<template>
    <div class="row mb-2 justify-center font-20 font-medium">Tìm kiếm chuyến đi</div>

    <div class="content-popup pb-3">
        <DxValidationGroup ref="formValidation">
            <div class="row">Từ ngày</div>
            <div class="row mb-4">
                <div class="xs12">
                    <DxDateBox
                        v-model="TuNgay"
                        displayFormat="dd/MM/yyyy"
                        :use-mask-behavior="true"
                        ref="NgayKhoiHanh"
                        dropDownButtonTemplate="customIcon"
                        validationMessageMode="always"
                        styling-mode="underlined"
                    >
                        <template #customIcon="{}">
                            <DxButton icon="mdi mdi-calendar" />
                        </template>
                        <DxValidator>
                            <DxRequiredRule message="Từ ngày không được bỏ trống!" />
                            <DxRangeRule
                                :max="DenNgay"
                                message="Từ ngày phải nhỏ hơn hoặc bằng đến ngày!"
                            />
                            <DxRangeRule
                                :min="MinDate"
                                :message="$i18n.t('DefaultString.MinDate')"
                            />
                        </DxValidator>
                    </DxDateBox>
                </div>
            </div>
            <div class="row">Đến ngày</div>
            <div class="row mb-4">
                <div class="xs12">
                    <DxDateBox
                        v-model="DenNgay"
                        displayFormat="dd/MM/yyyy"
                        :use-mask-behavior="true"
                        ref="NgayKhoiHanh"
                        dropDownButtonTemplate="customIcon"
                        validationMessageMode="always"
                        styling-mode="underlined"
                    >
                        <template #customIcon="{}">
                            <DxButton icon="mdi mdi-calendar" />
                        </template>
                        <DxValidator>
                            <DxRequiredRule message="Đến ngày không được bỏ trống!" />
                            <DxRangeRule
                                :max="MaxDate"
                                :message="$i18n.t('DefaultString.MaxDate')"
                            />
                            <DxRangeRule
                                :min="TuNgay"
                                message="Đến ngày phải lớn hơn hoặc bằng từ ngày!"
                            />
                        </DxValidator>
                    </DxDateBox>
                </div>
            </div>
        </DxValidationGroup>
    </div>

    <div class="row justify-space-between">
        <DxButton text="Hủy" type="default" styling-mode="text" @click="hiddenPopup" />
        <DxButton
            text="Tìm kiếm"
            type="default"
            styling-mode="contained"
            @click="TimKiemChuyenDi()"
        />
    </div>
</template>

<script>
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { DxValidator, DxRequiredRule, DxRangeRule } from "devextreme-vue/validator";
import { DxButton, DxDateBox } from "devextreme-vue";
export default {
    components: {
        DxValidationGroup,
        DxValidator,
        DxRequiredRule,
        DxRangeRule,
        DxDateBox,
        DxButton,
    },
    data() {
        return {
            DenNgay: new Date(),
            TuNgay: new Date(),
        };
    },
    computed: {
        MaxDate() {
            return new Date(this.$t("DefaultNumBer.MaxDate"));
        },
        MinDate() {
            return new Date(this.$t("DefaultNumBer.MinDate"));
        },
        ChuyenDiCuaLaiXe() {
            return this.$store.state.BaoCao.ChuyenDiCuaLaiXe;
        },
    },
    watch: {},
    methods: {
        hiddenPopup() {
            this.$emit("hiddenPopup");
        },
        TimKiemChuyenDi() {
            let validate = this.$refs.formValidation.instance.validate();
            if (validate.isValid) {
                this.hiddenPopup();
                this.$emit("TimKiemChuyenDi", {
                    TuNgay: this.TuNgay,
                    DenNgay: this.DenNgay,
                });
            } else {
                this.$Helper.autoFocusError(validate);
            }
        },
    },
    created() {
        this.TuNgay = this.$Helper.Common.getStartDay(this.ChuyenDiCuaLaiXe.TuNgay);
        this.DenNgay = this.$Helper.Common.getEndDay(this.ChuyenDiCuaLaiXe.DenNgay);
    },
    mounted() {},
};
</script>

<style scoped>
.content-popup {
    height: calc(100% - 64px);
}
</style>
