<template>
    <div class="frame-chuyen-di">
        <div
            :class="breakSpace"
            class="row justify-center font-16 font-medium color-primary"
        >
            {{
                item.thoiGianXuatBen ? $Helper.formatDateTime(item.thoiGianXuatBen) : null
            }}
        </div>
        <div
            :class="breakSpace"
            class="row align-center font-14 font-medium color-success"
        >
            <i class="mdi mdi-card-bulleted-outline mr-2"></i>
            {{ `${item.bienKiemSoat}` }}
        </div>
        <div :class="breakSpace" class="row align-center font-14 font-medium">
            <i class="mdi mdi-road mr-2"></i>
            {{ `${item.tuyenVanChuyen} (${item.maTuyen})` }}
        </div>
        <div :class="breakSpace" class="row align-center font-14 font-medium">
            <i class="mdi mdi-bus-side mr-2"></i>{{ `Chuyến đi: ${item.maChuyenDi}` }}
        </div>
        <div :class="breakSpace" class="row align-center font-14 font-medium">
            <i class="mdi mdi-script-text-outline mr-2"></i>
            {{ `Lệnh: ${item.maLenhLienKet}` }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: { type: Object, default: () => {} },
    },
    data() {
        return {
            breakSpace: "break-spaces",
        };
    },
};
</script>

<style scoped>
.frame-chuyen-di {
    box-shadow: 0px 1px 5px #dadce0;
    padding: 12px 8px;
    border: 1px solid #dadce0;
    margin-top: 8px;
    border-radius: 8px;
}
.ke-ngang-tuyen {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.2px;
}
.break-spaces {
    white-space: break-spaces;
}
</style>
