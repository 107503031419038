<template>
    <DxPopup
        v-if="ifDialog"
        :visible="dialog"
        :close-on-outside-click="closeOnOutsideClick"
        :show-close-button="false"
        :show-title="false"
        :width="width"
        :height="height"
        :maxHeight="maxHeight"
        :shading="shading"
        :onHidden="onHiddenPopup"
        :copyRootClassesToWrapper="true"
        container=".dx-viewport"
        position="bottom"
        style="position: relative"
        :animation="{
            show: {
                type: 'slide',
                duration: 400,
                from: {
                    position: {
                        my: 'top',
                        at: 'bottom',
                        of: 'window',
                    },
                },
            },
            hide: {
                type: 'slide',
                duration: 400,
                to: {
                    position: {
                        my: 'top',
                        at: 'bottom',
                        of: 'window',
                    },
                },
            },
        }"
        :class="classPopup"
        class="popup-bottom"
    >
        <template #content>
            <DxScrollView
                id="scrollview"
                ref="scrollViewWidget"
                width="100%"
                height="100%"
            >
                <div class="row" style="height: auto">
                    <div class="xs12" style="position: relative">
                        <div style="position: absolute" v-if="showCloseButton">
                            <DxButton
                                type="normal"
                                styling-mode="text"
                                icon="mdi mdi-close"
                                style="
                                    position: absolute;
                                    top: -17px;
                                    left: -14px;
                                    z-index: 1;
                                "
                                @click="onHiddenPopup()"
                            />
                        </div>
                    </div>
                </div>
                <slot name="content"></slot>
            </DxScrollView>
        </template>
    </DxPopup>
</template>

<script>
import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView, DxButton } from "devextreme-vue";
export default {
    components: {
        DxPopup,
        DxButton,
        DxScrollView,
    },
    props: {
        width: { type: String, default: "100%" },
        height: { type: String, default: "60%" },
        shading: { type: Boolean, default: true },
        showCloseButton: { type: Boolean, default: false },
        dialog: { type: Boolean, default: false },
        title: { type: String, default: "Tiêu đề" },
        classPopup: { type: String, default: "popup-style" },
        closeOnOutsideClick: { type: Boolean, default: true },
        maxHeight: { type: String || Number, default: null },
    },
    data() {
        return {
            ifDialog: true,
        };
    },
    watch: {
        dialog: {
            handler: function () {
                if (this.dialog == true) {
                    this.ifDialog = true;
                }
            },
            immediate: true,
        },
    },
    methods: {
        onHiddenPopup(e) {
            this.ifDialog = false;
            this.$emit("update:dialog", false);
        },
    },
};
</script>

<style>
</style>