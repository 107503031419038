<template>
    <!-- group: { selector: 'NgayXuatBenDuKien', desc: false }, -->
    <DxList
        :data-source="{
            store: DanhSachChuyenDi,
        }"
        class="list-chuyen-di-ban-ve"
        height="100%"
        :onItemClick="onItemClick"
    >
        <!-- :collapsible-groups="true"
        :grouped="true"
        <template #group="{ data: item }">
            <div class="font-16 font-medium" @click="onItemClick(item)">
                {{ item.key ? $Helper.formatDate(item.key) : "Ngày" }}
            </div>
        </template> -->
        <template #item="{ data: item }">
            <ItemChuyenDiVue :item="item" />
        </template>
    </DxList>
</template>

<script>
import { DxList } from "devextreme-vue";
import { mapMutations } from "vuex";
import ItemChuyenDiVue from "./ItemChuyenDi.vue";
export default {
    components: {
        DxList,
        ItemChuyenDiVue,
    },
    data() {
        return {
            DanhSachChuyenDi: [],
        };
    },
    methods: {
        async onItemClick(e) {
            console.log("🚀 ~ onItemClick ~ e:", e);
            this.$router.push({
                path: "/Bao-Cao/Danh-Sach-Hanh-Khach-Mua-Ve",
                query: {
                    IdChuyenDi: e.itemData.idChuyenDi,
                    GioXuatBen: e.itemData.thoiGianXuatBen,
                    BienKiemSoat: e.itemData.bienKiemSoat,
                    TuyenVanChuyen: e.itemData.tuyenVanChuyen,
                    MaTuyen: e.itemData.maTuyen,
                },
            });
        },
        async TimKiemChuyenDi() {
            try {
                this.DanhSachChuyenDi = (
                    await this.$store.dispatch("BaoCao/Get_DanhSachChuyenDiCuaLaiXe")
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
    },
    mounted() {},
};
</script>

<style>
.list-chuyen-di-ban-ve .dx-item.dx-list-item {
    overflow: unset;
    margin-bottom: 12px;
}
.list-chuyen-di-ban-ve .dx-list-group-body {
    max-height: calc(100vh - 36px - 36px - 48px - 44px - 40px);
    overflow: auto;
}
.list-chuyen-di-ban-ve .dx-list-group-header {
    color: #fb8c00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-direction: row-reverse;
    padding: 8px 8px 4px;
    border: 1px solid #dadce0;
    border-radius: 4px;
}
</style>
