<template>
    <ion-page data-pageid="tab2">
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div class="shrink">
                        <!-- <DxButton
                            type="default"
                            styling-mode="outlined"
                            icon="mdi mdi-chevron-down"
                            class="ml-1"
                        /> -->
                        <DxButton
                            type="default"
                            icon="mdi mdi-chevron-left"
                            class="ml-1"
                            @click="$router.go(-1)"
                        />
                        <!-- <DxButton
                            type="default"
                            icon="mdi mdi-help-circle-outline"
                            class="ml-1"
                            style="opacity: 0"
                        /> -->
                    </div>
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">DANH SÁCH CHUYẾN ĐI</div>
                </slot>
                <slot name="after">
                    <div class="shrink">
                        <DxButton
                            icon="mdi mdi-filter-variant"
                            type="default"
                            @click="
                                () => {
                                    $store.commit('NhanVienBanVe/Set', {
                                        key: 'ProcessGetDiemXuatPhat',
                                        data: false,
                                    });
                                    $store.commit('NhanVienBanVe/Set', {
                                        key: 'ProcessGetDiemDen',
                                        data: false,
                                    });
                                    PopupLocDuLieu = true;
                                }
                            "
                        />
                        <!-- <DxButton
                            v-if="
                                $Core.AuthApi.TokenParsed.preferred_username !=
                                '0888888888'
                            "
                            type="default"
                            icon="mdi mdi-help-circle-outline"
                            class="ml-1"
                            @click="MoTrangHuongDan"
                        /> -->
                    </div>
                </slot>
            </div>
        </ion-toolbar>

        <ion-content :scroll-events="false">
            <div class="row mb-2 frame-sap-xep">
                <SapXepVue @TimKiemChuyenDi="TimKiemChuyenDi" />
            </div>

            <div class="row px-2">
                <div class="xs12 danh-sach-chuyen-di">
                    <DanhSachChuyenDiVue ref="DanhSachChuyenDiVue" />
                </div>
            </div>
        </ion-content>

        <TempPopupVue
            v-model:dialog="PopupLocDuLieu"
            height="auto"
            maxHeight="80%"
            class="popup-padding-16"
        >
            <template #content>
                <PopupTimKiemChuyenDiVue
                    ref="PopupTimKiemChuyenDiVue"
                    @hiddenPopup="hiddenPopup"
                    @TimKiemChuyenDi="TimKiemChuyenDi"
                />
            </template>
        </TempPopupVue>
    </ion-page>
</template>

<script>
import config from "devextreme/core/config";
import {
    IonContent,
    IonPage,
    IonToolbar,
    onIonViewWillEnter,
    onIonViewWillLeave,
} from "@ionic/vue";
import { DxButton } from "devextreme-vue";
import PopupTimKiemChuyenDiVue from "./components/PopupTimKiemChuyenDi.vue";
import TempPopupVue from "@/components/_Common/TempPopup.vue";
import SapXepVue from "./components/SapXep.vue";
import DanhSachChuyenDiVue from "./components/DanhSachChuyenDi.vue";
export default {
    components: {
        IonContent,
        IonPage,
        DxButton,
        IonToolbar,
        PopupTimKiemChuyenDiVue,
        TempPopupVue,
        SapXepVue,
        DanhSachChuyenDiVue,
    },
    data() {
        return {
            PopupLocDuLieu: false,
        };
    },
    computed: {
        ChuyenDiCuaLaiXe: {
            get() {
                return this.$store.state.BaoCao.ChuyenDiCuaLaiXe;
            },
            set(data) {
                this.$store.commit("BaoCao/Set", {
                    key: "ChuyenDiCuaLaiXe",
                    data: data,
                });
            },
        },
    },
    methods: {
        hiddenPopup() {
            this.PopupLocDuLieu = false;
        },
        TimKiemChuyenDi({ TuNgay, DenNgay }) {
            this.ChuyenDiCuaLaiXe.TuNgay = this.$Helper.Common.getStartDay(
                TuNgay || this.ChuyenDiCuaLaiXe.TuNgay || new Date(),
            );
            this.ChuyenDiCuaLaiXe.DenNgay = this.$Helper.Common.getEndDay(
                DenNgay || this.ChuyenDiCuaLaiXe.DenNgay || new Date(),
            );
            let interval = setInterval(() => {
                if (this.$refs.DanhSachChuyenDiVue) {
                    clearInterval(interval);
                    this.$refs.DanhSachChuyenDiVue.TimKiemChuyenDi();
                }
            }, 300);
        },
        MoTrangHuongDan() {
            this.$router.push({
                path: "/huong-dan-su-dung/Xem-Chi-Tiet-Huong-Dan",
                query: {
                    name: this.$i18n.t("UrlHuongDan.BanVeUyThacChoNhanVien"),
                },
            });
        },
    },
    mounted() {
        config({
            floatingActionButtonConfig: {
                position: {
                    at: "right bottom",
                    my: "right bottom",
                    offset: "-16 -44",
                },
                shading: true,
            },
        });
    },
    created() {
        onIonViewWillEnter(() => {
            this.TimKiemChuyenDi({});
        });
    },
};
</script>

<style scoped>
.frame-sap-xep {
    height: 48px;
    border-bottom: 1px solid #dadce0;
    box-shadow: 0px 0px 10px 1px #dadce0;
}
.danh-sach-chuyen-di {
    height: calc(100vh - 48px - 48px);
}
</style>
